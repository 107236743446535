exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apie-mus-jsx": () => import("./../../../src/pages/apie-mus.jsx" /* webpackChunkName: "component---src-pages-apie-mus-jsx" */),
  "component---src-pages-biuru-valymas-jsx": () => import("./../../../src/pages/biuru-valymas.jsx" /* webpackChunkName: "component---src-pages-biuru-valymas-jsx" */),
  "component---src-pages-cheminis-baldu-valymas-jsx": () => import("./../../../src/pages/cheminis-baldu-valymas.jsx" /* webpackChunkName: "component---src-pages-cheminis-baldu-valymas-jsx" */),
  "component---src-pages-fasadu-valymas-jsx": () => import("./../../../src/pages/fasadu-valymas.jsx" /* webpackChunkName: "component---src-pages-fasadu-valymas-jsx" */),
  "component---src-pages-gamybos-patalpu-valymas-jsx": () => import("./../../../src/pages/gamybos-patalpu-valymas.jsx" /* webpackChunkName: "component---src-pages-gamybos-patalpu-valymas-jsx" */),
  "component---src-pages-generalinis-valymas-jsx": () => import("./../../../src/pages/generalinis-valymas.jsx" /* webpackChunkName: "component---src-pages-generalinis-valymas-jsx" */),
  "component---src-pages-index-hero-section-jsx": () => import("./../../../src/pages/index/HeroSection.jsx" /* webpackChunkName: "component---src-pages-index-hero-section-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-pricing-section-jsx": () => import("./../../../src/pages/index/PricingSection.jsx" /* webpackChunkName: "component---src-pages-index-pricing-section-jsx" */),
  "component---src-pages-index-questions-section-jsx": () => import("./../../../src/pages/index/QuestionsSection.jsx" /* webpackChunkName: "component---src-pages-index-questions-section-jsx" */),
  "component---src-pages-index-services-section-jsx": () => import("./../../../src/pages/index/ServicesSection.jsx" /* webpackChunkName: "component---src-pages-index-services-section-jsx" */),
  "component---src-pages-index-why-us-section-jsx": () => import("./../../../src/pages/index/WhyUsSection.jsx" /* webpackChunkName: "component---src-pages-index-why-us-section-jsx" */),
  "component---src-pages-kontaktai-jsx": () => import("./../../../src/pages/kontaktai.jsx" /* webpackChunkName: "component---src-pages-kontaktai-jsx" */),
  "component---src-pages-langu-valymas-jsx": () => import("./../../../src/pages/langu-valymas.jsx" /* webpackChunkName: "component---src-pages-langu-valymas-jsx" */),
  "component---src-pages-parduotuviu-valymas-jsx": () => import("./../../../src/pages/parduotuviu-valymas.jsx" /* webpackChunkName: "component---src-pages-parduotuviu-valymas-jsx" */),
  "component---src-pages-patalpu-dezinfekavimas-jsx": () => import("./../../../src/pages/patalpu-dezinfekavimas.jsx" /* webpackChunkName: "component---src-pages-patalpu-dezinfekavimas-jsx" */),
  "component---src-pages-periodinis-valymas-jsx": () => import("./../../../src/pages/periodinis-valymas.jsx" /* webpackChunkName: "component---src-pages-periodinis-valymas-jsx" */),
  "component---src-pages-postatybinis-valymas-jsx": () => import("./../../../src/pages/postatybinis-valymas.jsx" /* webpackChunkName: "component---src-pages-postatybinis-valymas-jsx" */),
  "component---src-pages-sandeliavimo-patalpu-valymas-jsx": () => import("./../../../src/pages/sandeliavimo-patalpu-valymas.jsx" /* webpackChunkName: "component---src-pages-sandeliavimo-patalpu-valymas-jsx" */),
  "component---src-pages-sezoninis-langu-valymas-jsx": () => import("./../../../src/pages/sezoninis-langu-valymas.jsx" /* webpackChunkName: "component---src-pages-sezoninis-langu-valymas-jsx" */),
  "component---src-pages-sporto-klubu-valymas-jsx": () => import("./../../../src/pages/sporto-klubu-valymas.jsx" /* webpackChunkName: "component---src-pages-sporto-klubu-valymas-jsx" */),
  "component---src-pages-subpage-how-cleaning-done-jsx": () => import("./../../../src/pages/subpage/HowCleaningDone.jsx" /* webpackChunkName: "component---src-pages-subpage-how-cleaning-done-jsx" */),
  "component---src-pages-subpage-how-cleaning-done-postatybinis-jsx": () => import("./../../../src/pages/subpage/HowCleaningDonePostatybinis.jsx" /* webpackChunkName: "component---src-pages-subpage-how-cleaning-done-postatybinis-jsx" */),
  "component---src-pages-subpage-how-cleaning-image-jsx": () => import("./../../../src/pages/subpage/HowCleaningImage.jsx" /* webpackChunkName: "component---src-pages-subpage-how-cleaning-image-jsx" */),
  "component---src-pages-subpage-subpage-hero-section-jsx": () => import("./../../../src/pages/subpage/SubpageHeroSection.jsx" /* webpackChunkName: "component---src-pages-subpage-subpage-hero-section-jsx" */)
}

